import dotProp from 'dot-prop-immutable';
import generateId from '../../../utils/generateId';
import { isNumber } from 'util';
import { getAllFields } from '../../../internal-components/form-builder/getAllFields';
import { cloneDeep } from 'lodash';
import { fieldTypes } from '../../../internal-components/form-builder/fieldTypes';
import SerializationCopy from '../../../utils/SerializationCopy';
import getWorldCheckOptions from '../../../utils/getWorldCheckOptions';
import getCredasOptions from '../../../utils/getCredasOptions';
import {
  currencies,
  defaultCurrency
} from '../../../internal-components/form-builder/currencies';

export const LOAD_FORM = 'state/formBuilder/LOAD_FORM';
export const UPDATE_FORM = 'state/formBuilder/UPDATE_FORM';
export const REORDER_FIELDS = 'state/formBuilder/REORDER_FIELDS';
export const REORDER_SECTIONS = 'state/formBuilder/REORDER_SECTIONS';
export const ADD_FIELD = 'state/formBuilder/ADD_FIELD';
export const DELETE_FIELD = 'state/formBuilder/DELETE_FIELD';
export const DELETE_FIELDS = 'state/formBuilder/DELETE_FIELDS';
export const UPDATE_FIELD = 'state/formBuilder/UPDATE_FIELD';
export const UPDATE_CURRENCY_CONVERSION_FIELD =
  'state/formBuilder/UPDATE_CURRENCY_CONVERSION_FIELD';
export const UPDATE_LOOKUP_FIELD = 'state/formBuilder/UPDATE_LOOKUP_FIELD';
export const UPDATE_SCORE_FIELD = 'state/formBuilder/UPDATE_SCORE_FIELD';
export const DUPLICATE_FIELD = 'state/formBuilder/DUPLICATE_FIELD';
export const UPDATE_FIELD_ACCESS = 'state/formBuilder/UPDATE_FIELD_ACCESS';
export const MULTI_UPDATE_FIELD_ACCESS =
  'state/formBuilder/MULTI_UPDATE_FIELD_ACCESS';
export const MULTISET = 'state/formBuilder/MULTISET';

const currencyOptions = Object.values(currencies).map(one => one.name);

const worldCheckOptions = getWorldCheckOptions();
const credasOptions = getCredasOptions();

const FIELD_TYPES_WITH_SUBFIELDS = [
  'lookup',
  'user_lookup',
  'worldcheck',
  'worldcheck_org',
  'credas',
  'credas_out_of_scope',
  'thirdfort',
  'currency_conversion'
];

/*
IF ADDING/CHANGING FIELDS TYPES WITH SUBFIELDS, MAY NEED TO UPDATE 
getFieldsThatShouldBeUnchanged() in FormEngine.js
*/

const THIRDFORT_SUBFIELDS = [
  {
    title: `Transaction name`,
    internalId: 'transactionName',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Check types`,
    internalId: 'checkTypes',
    type: 'text',
    uiType: 'multiple_choice',
    editable: true,
    options: [
      'Enhanced NFC ID',
      'Proof of Ownership (seller)',
      'Ongoing Monitoring',
      'International Address Verification'
    ],
    defaultValue: {
      properties: {
        value: '||Enhanced NFC ID||'
      }
    }
  },
  {
    title: `Individual's first name`,
    internalId: 'firstName',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Individual's last name`,
    internalId: 'lastName',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Mobile`,
    internalId: 'phone',
    type: 'phone',
    uiType: 'phone',
    editable: true,
    required: true
  },
  {
    title: `thirdfort status`,
    internalId: 'status',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Date of check`,
    internalId: 'date',
    type: 'number',
    uiType: 'date'
  }
];
const CREDAS_SUBFIELDS = [
  {
    title: `Individual's first name`,
    internalId: 'firstName',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Individual's middle name`,
    internalId: 'middleName',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Individual's last name`,
    internalId: 'surname',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Individual's email address`,
    internalId: 'email',
    type: 'email_address',
    uiType: 'email_address',
    editable: true,
    required: true
  },
  {
    title: `Mobile`,
    internalId: 'phone',
    type: 'phone',
    uiType: 'phone',
    editable: true,
    required: true,
    options: ['us', 'au', 'fr', 'de', 'ie', 'it', 'es', 'gb']
  },
  {
    title: `Address line 1`,
    internalId: 'addressLine1',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Address line 2`,
    internalId: 'addressLine2',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Address line 3`,
    internalId: 'addressLine3',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Address line 4`,
    internalId: 'addressLine4',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `City`,
    internalId: 'city',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `County`,
    internalId: 'county',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Postcode`,
    internalId: 'postcode',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Country`,
    internalId: 'country',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    required: true,
    options: credasOptions.countries
  },
  {
    title: `Date of birth`,
    internalId: 'dateOfBirth',
    type: 'number',
    uiType: 'date',
    required: true,
    editable: true
  },
  {
    title: `Check type`,
    description: 'Which type of Credas check is this',
    internalId: 'checktype',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: ['Full ID verification', 'Client screening only', 'No check']
  },
  {
    title: `Run LOE check?`,
    internalId: 'loe',
    type: 'text',
    uiType: 'confirmation',
    editable: true,
    options: ['Yes']
  },
  {
    title: `Integration title`,
    description: 'Give this check a name',
    internalId: 'title',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `How would you like to send the check to this user?`,
    description: `If the 'Check Type' is *not* 'Full ID Verification,' answering this question is **not required**.  
*Please Note:* The check will only be sent to the user if the 'Check Type' has been set to 'Full ID Verification.'`,
    internalId: 'contactVia',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: ['email', 'phone']
  },
  {
    title: `Address match`,
    internalId: 'addressMatch',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `LOE status`,
    internalId: 'loeStatus',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Credas status`,
    internalId: 'status',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Date of check`,
    internalId: 'date',
    type: 'number',
    uiType: 'date'
  }
];

const CREDAS_OUT_OF_SCOPE_SUBFIELDS = [
  {
    title: `Integration title`,
    description: 'Give this check a name',
    internalId: 'title',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Individual's first name`,
    internalId: 'firstName',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Individual's middle name`,
    internalId: 'middleName',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Individual's last name`,
    internalId: 'surname',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Address line 1`,
    internalId: 'addressLine1',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Address line 2`,
    internalId: 'addressLine2',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `City`,
    internalId: 'city',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `County`,
    internalId: 'county',
    type: 'text',
    uiType: 'text',
    editable: true
  },
  {
    title: `Postcode`,
    internalId: 'postcode',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Country`,
    internalId: 'country',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Date of birth`,
    internalId: 'dateOfBirth',
    type: 'number',
    uiType: 'date',
    editable: true,
    required: true
  },
  {
    title: `Individual's phone number`,
    internalId: 'phone',
    type: 'phone',
    uiType: 'phone',
    editable: true,
    options: ['us', 'au', 'fr', 'de', 'ie', 'it', 'es', 'gb']
  },
  {
    title: `Individual's email address`,
    internalId: 'email',
    type: 'email_address',
    uiType: 'email_address',
    editable: true
  },
  {
    title: `Credas status`,
    internalId: 'status',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Date of check`,
    internalId: 'date',
    type: 'number',
    uiType: 'date'
  }
];

const WORLDCHECK_SUBFIELDS = [
  {
    title: `First and last name`,
    internalId: 'name',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `Gender`,
    internalId: 'gender',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: ['FEMALE', 'MALE']
  },
  {
    title: `DOB`,
    internalId: 'dateOfBirth',
    type: 'number',
    uiType: 'date',
    editable: true
  },
  {
    title: `Country location`,
    internalId: 'countryLocation',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: worldCheckOptions.countries
  },
  {
    title: `Place of birth`,
    internalId: 'placeOfBirth',
    type: 'text',
    uiType: 'text',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: worldCheckOptions.countries
  },
  {
    title: `Citizenship`,
    internalId: 'nationality',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: worldCheckOptions.nationalities
  },
  {
    title: `worldcheck status`,
    internalId: 'status',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Date of check`,
    internalId: 'date',
    type: 'number',
    uiType: 'date'
  }
];

const WORLDCHECK_ORG_SUBFIELDS = [
  {
    title: `Company name`,
    internalId: 'name',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: true
  },
  {
    title: `ID Number`,
    internalId: 'documentId',
    type: 'text',
    uiType: 'text',
    editable: true,
    required: false
  },
  {
    title: `ID Issuer/Country`,
    internalId: 'documentIdCountry',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    required: false,
    options: worldCheckOptions.countries
  },
  {
    title: `ID Type`,
    internalId: 'documentIdType',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    required: false,
    options: []
  },
  {
    title: `Registered Country`,
    internalId: 'registeredCountry',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    required: false,
    options: worldCheckOptions.countries
  },
  {
    title: `worldcheck status`,
    internalId: 'status',
    type: 'text',
    uiType: 'text'
  },
  {
    title: `Date of check`,
    internalId: 'date',
    type: 'number',
    uiType: 'date'
  }
];

const CURRENCY_CONVERSION_SUBFIELDS = [
  {
    title: 'Currency',
    internalId: 'source_currency',
    type: 'enum',
    uiType: 'dropdown',
    editable: true,
    options: currencyOptions,
    defaultValue: { properties: { value: defaultCurrency } }
  },
  {
    title: 'Value',
    internalId: 'source_value',
    type: 'number',
    uiType: 'number',
    editable: true
  },
  {
    title: 'Conversion date',
    internalId: 'conversion_date',
    type: 'number',
    uiType: 'date',
    editable: true
  },
  {
    title: `Converted value in ${currencies[defaultCurrency].symbol} (${currencies[defaultCurrency].name})`,
    internalId: 'converted_value',
    type: 'number',
    uiType: 'currency',
    editable: false
  }
];

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, numberToMove = 1) => {
  const result = Array.from(list);
  const removed = result.splice(startIndex, numberToMove);
  result.splice(endIndex, 0, ...removed);

  return result;
};

/**
 * Returns a function that merges a given payload into an object. Intended as a third argument in `dotProp.set`, which gets passed the current value as a parameter.
 *
 * ```js
 * const object = { a: 1, b: 2};
 * const payload = { a: 3 };
 *
 * const newObject = dotProp.set(object, path, mergePayload(payload))
 * // { a: 3, b: 2}
 * ```
 *
 * @param {object} payload
 */
const mergePayload =
  (payload = {}) =>
  (v = {}) => ({
    ...v,
    ...payload
  });

const reorderFields = (state, payload) => {
  let {
    id,
    source,
    destination,
    // all user and admin fields in one array, in current position
    allFields,
    // all user and admin fields as seen, not including subFields
    visibleFields
  } = payload;

  const needsReorder =
    source &&
    destination &&
    'index' in source &&
    'index' in destination &&
    source.index !== destination.index;

  if (!needsReorder) {
    return state;
  }

  // get currect form and fields as stored
  const form = dotProp.get(state, id);
  let { fields, adminFields } = form;

  const visibleSourceIndex = source.index;
  const visibleDestinationIndex = destination.index;

  let actualSourceIndex, actualDestinationIndex, numberToMove, subFieldCount;

  actualSourceIndex = visibleFields[visibleSourceIndex].position;
  actualDestinationIndex = visibleFields[visibleDestinationIndex].position;
  numberToMove = 1;

  if (
    FIELD_TYPES_WITH_SUBFIELDS.includes(
      visibleFields[visibleSourceIndex].uiType
    )
  ) {
    subFieldCount = 0;
    if (
      visibleFields[visibleSourceIndex].lookupInfo &&
      visibleFields[visibleSourceIndex].lookupInfo.subFields
    ) {
      subFieldCount =
        visibleFields[visibleSourceIndex].lookupInfo.subFields.length;
    }
    numberToMove = subFieldCount + 1;
    if (actualDestinationIndex > actualSourceIndex) {
      actualDestinationIndex = actualDestinationIndex - numberToMove + 1;
    }
  }

  if (
    FIELD_TYPES_WITH_SUBFIELDS.includes(
      visibleFields[visibleDestinationIndex].uiType
    )
  ) {
    if (actualDestinationIndex > actualSourceIndex) {
      subFieldCount = 0;
      if (
        visibleFields[visibleDestinationIndex].lookupInfo &&
        visibleFields[visibleDestinationIndex].lookupInfo.subFields
      ) {
        subFieldCount =
          visibleFields[visibleDestinationIndex].lookupInfo.subFields.length;
      }
      actualDestinationIndex = actualDestinationIndex + subFieldCount;
    }
  }

  // reorder fields
  allFields = reorder(
    allFields,
    actualSourceIndex,
    actualDestinationIndex,
    numberToMove
  );

  // map to fields to ids
  const ids = allFields.map(f => f.internalId);

  // returns a field with a new position prop, using its index in `fields`
  const applyNewOrderProp = o => {
    let newPosition = ids.findIndex(_id => _id === o.internalId);
    if (newPosition < 0) {
      newPosition = fields.length;
    }

    return {
      ...o,
      position: newPosition
    };
  };

  // apply new position props to all fields
  fields = fields.map(applyNewOrderProp);
  adminFields = adminFields.map(applyNewOrderProp);

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

const reorderSections = (state, payload) => {
  let { id, newSectionOrder } = payload;
  const form = dotProp.get(state, id);
  let { fields, adminFields } = form;
  const fieldsCopy = SerializationCopy(fields);
  const adminFieldsCopy = SerializationCopy(adminFields);
  fieldsCopy.forEach(one => {
    one.adminField = false;
  });
  adminFieldsCopy.forEach(one => {
    one.adminField = true;
  });
  const allFields = [...fieldsCopy, ...adminFieldsCopy];
  allFields.sort((a, b) => a.position - b.position);
  const allFieldsBySection = {};
  let currentSectionId = 'unnamed-starting-section';
  let allFieldsOfCurrentSection = [];
  allFields.forEach(one => {
    if (one.uiType === 'section_break') {
      allFieldsBySection[currentSectionId] = allFieldsOfCurrentSection;
      allFieldsOfCurrentSection = [];
      currentSectionId = one.internalId;
    }
    allFieldsOfCurrentSection.push(one);
  });
  // handle the final section
  if (currentSectionId && !allFieldsBySection[currentSectionId]) {
    allFieldsBySection[currentSectionId] = allFieldsOfCurrentSection;
  }
  const newSectionIdOrder = [
    'unnamed-starting-section',
    ...newSectionOrder.map(one => one.internalId)
  ];
  const newFields = [];
  const newAdminFields = [];
  let nextFieldPosition = 0;

  newSectionIdOrder.forEach(one => {
    const fieldsOfThisSection = allFieldsBySection[one];
    let isAdminField;
    if (fieldsOfThisSection && fieldsOfThisSection.length) {
      fieldsOfThisSection.forEach(oneField => {
        isAdminField = oneField.adminField;
        delete oneField.adminField;
        oneField.position = nextFieldPosition;
        nextFieldPosition++;
        if (isAdminField) {
          newAdminFields.push(oneField);
        } else {
          newFields.push(oneField);
        }
      });
    }
  });

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields: newFields,
    adminFields: newAdminFields
  });
};

const updateCurrencyConversionField = (state, payload) => {
  const { id, isAdminOnly, fieldId, field, converted_title, options } = payload;
  const form = dotProp.get(state, id);
  let { fields, adminFields } = form;
  let fieldsOfThisKind, mySubFields, convertedSubfield;

  if (isAdminOnly) {
    fieldsOfThisKind = adminFields;
  } else {
    fieldsOfThisKind = fields;
  }
  mySubFields = fieldsOfThisKind.filter(
    one =>
      one.lookupInfo &&
      one.lookupInfo.subFieldInfo &&
      one.lookupInfo.subFieldInfo.parentFieldId === fieldId
  );

  convertedSubfield = mySubFields.find(
    one => one.lookupInfo.subFieldInfo.externalFormFieldId === 'converted_value'
  );

  findFieldAndMutate(fields, convertedSubfield.internalId, {
    title: converted_title,
    options
  });
  findFieldAndMutate(adminFields, convertedSubfield.internalId, {
    title: converted_title,
    options
  });

  findFieldAndMutate(fields, fieldId, field);
  findFieldAndMutate(adminFields, fieldId, field);

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

const updateLookupField = (state, payload) => {
  const { id, isAdminOnly, fieldId, field, subFieldsToAdd, subFieldsToDelete } =
    payload;
  const form = dotProp.get(state, id);
  let { fields, adminFields } = form;
  let internalId, newField, subFieldIdsToDelete;
  let fieldsOfThisKind,
    fieldsOfOtherKind,
    fieldsOfThisKindWithoutMySubFields,
    mySubFields,
    subFieldOrder,
    indexOfLookupField;
  let positionOfLookup,
    positionOfSubField,
    otherFieldsWhichNeedPositionIncreased;

  findFieldAndMutate(fields, fieldId, field);
  findFieldAndMutate(adminFields, fieldId, field);

  subFieldsToAdd.forEach(one => {
    internalId = generateFieldId();
    newField = {
      options: [],
      ...one,
      position: -1,
      internalId,
      required: false
    };
    if (isAdminOnly) {
      adminFields.push(newField);
    } else {
      fields.push(newField);
    }
  });

  subFieldIdsToDelete = subFieldsToDelete.map(one => one.internalId);

  if (isAdminOnly) {
    fieldsOfThisKind = adminFields;
    fieldsOfOtherKind = fields;
  } else {
    fieldsOfThisKind = fields;
    fieldsOfOtherKind = adminFields;
  }
  mySubFields = fieldsOfThisKind.filter(
    one =>
      one.lookupInfo &&
      one.lookupInfo.subFieldInfo &&
      one.lookupInfo.subFieldInfo.parentFieldId === fieldId
  );
  fieldsOfThisKindWithoutMySubFields = fieldsOfThisKind.filter(
    one =>
      !one.lookupInfo ||
      !one.lookupInfo.subFieldInfo ||
      one.lookupInfo.subFieldInfo.parentFieldId !== fieldId
  );
  subFieldOrder = field.lookupInfo.subFields.map(one => one.internalId);

  mySubFields.sort((a, b) => {
    let indexA, indexB;
    indexA = subFieldOrder.indexOf(
      a.lookupInfo.subFieldInfo.externalFormFieldId
    );
    indexB = subFieldOrder.indexOf(
      b.lookupInfo.subFieldInfo.externalFormFieldId
    );
    if (indexA === indexB) return 0;
    if (indexA < indexB) return -1;
    return 1;
  });

  indexOfLookupField = fieldsOfThisKindWithoutMySubFields.length - 1;
  positionOfLookup = fieldsOfThisKindWithoutMySubFields.length - 1;
  fieldsOfThisKindWithoutMySubFields.forEach((one, i) => {
    if (one.internalId === fieldId) {
      indexOfLookupField = i;
      positionOfLookup = one.position;
    }
  });

  positionOfSubField = positionOfLookup;

  mySubFields.forEach(one => {
    if (subFieldIdsToDelete.indexOf(one.internalId) === -1) {
      positionOfSubField++;
      one.position = positionOfSubField;
    }
  });

  otherFieldsWhichNeedPositionIncreased =
    fieldsOfThisKindWithoutMySubFields.filter(
      one => one.position > positionOfLookup
    );
  otherFieldsWhichNeedPositionIncreased.forEach((one, i) => {
    one.position =
      one.position + subFieldsToAdd.length - subFieldsToDelete.length;
  });
  otherFieldsWhichNeedPositionIncreased = fieldsOfOtherKind.filter(
    one => one.position > positionOfLookup
  );
  otherFieldsWhichNeedPositionIncreased.forEach(one => {
    one.position =
      one.position + subFieldsToAdd.length - subFieldsToDelete.length;
  });

  if (isAdminOnly) {
    adminFields = fieldsOfThisKindWithoutMySubFields;
    adminFields.splice(indexOfLookupField + 1, 0, ...mySubFields);
  } else {
    fields = fieldsOfThisKindWithoutMySubFields;
    fields.splice(indexOfLookupField + 1, 0, ...mySubFields);
  }

  fields = fields.filter(f => subFieldIdsToDelete.indexOf(f.internalId) === -1);
  adminFields = adminFields.filter(
    f => subFieldIdsToDelete.indexOf(f.internalId) === -1
  );

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

const updateScoreField = (state, payload) => {
  const { id, fieldId, formula } = payload;
  const form = dotProp.get(state, id);
  let matchingField;
  let { fields, adminFields } = form;
  matchingField =
    fields.find(one => one.internalId === fieldId) ||
    adminFields.find(one => one.internalId === fieldId);
  if (matchingField && matchingField) {
    matchingField.calculationInfo = { formula };
  }
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

const addField = (state, payload) => {
  const { id, field, after, position: specifiedPos } = payload;
  let form = dotProp.get(state, id) || {};
  let allFields = getAllFields(form);

  let adjustedAfter, fieldToGoAfter, childPositions;

  adjustedAfter = after;
  fieldToGoAfter = allFields.filter(one => one.position === adjustedAfter);
  if (fieldToGoAfter && fieldToGoAfter.length) {
    fieldToGoAfter = fieldToGoAfter[0];
    childPositions = allFields
      .filter(
        one =>
          one.lookupInfo &&
          one.lookupInfo.subFieldInfo &&
          one.lookupInfo.subFieldInfo.parentFieldId ===
            fieldToGoAfter.internalId
      )
      .map(f => f.position);
    if (childPositions.length) {
      adjustedAfter = Math.max(...childPositions);
    }
  }

  // position at end, assumes all position props are normalized
  const position =
    typeof specifiedPos === 'number' ? specifiedPos : allFields.length;

  // generate uinque ID for the field using form ID, timestamp, and current form state as inputs
  const internalId = generateFieldId();

  // if we defined default options in fieldTypes map - use them
  const uiType = field.uiType;
  const ftOptions = fieldTypes[uiType].options;
  const options = typeof ftOptions === 'object' ? ftOptions : [];

  let newFieldObject = {
    internalId,
    position,
    options,
    ...field
  };

  if (field.uiType === 'user_lookup' && !field.lookupInfo) {
    newFieldObject.lookupInfo = {
      entityType: 'user',
      subFields: []
    };
  }

  let subFieldData = [];

  switch (field.uiType) {
    case 'thirdfort':
      subFieldData = THIRDFORT_SUBFIELDS;
      break;
    case 'worldcheck':
      subFieldData = WORLDCHECK_SUBFIELDS;
      newFieldObject.checkType = 'INDIVIDUAL';
      newFieldObject.checkOptions = ['WATCHLIST'];
      break;
    case 'worldcheck_org':
      subFieldData = WORLDCHECK_ORG_SUBFIELDS;
      newFieldObject.checkType = 'ORGANISATION';
      newFieldObject.checkOptions = ['WATCHLIST'];
      break;
    case 'currency_conversion':
      subFieldData = CURRENCY_CONVERSION_SUBFIELDS;
      break;
    case 'credas':
      subFieldData = CREDAS_SUBFIELDS;
      break;
    case 'credas_out_of_scope':
      subFieldData = CREDAS_OUT_OF_SCOPE_SUBFIELDS;
      break;
    default:
      break;
  }
  subFieldData.forEach(one => {
    one.subFieldId = generateFieldId();
  });

  const amlSubfieldCount = subFieldData.length;

  const newAMLFieldPosition =
    typeof adjustedAfter === 'number' ? adjustedAfter : position;

  // for thirdfort, worldcheck, etc, adjust index of later fields first
  if (subFieldData.length) {
    let otherFieldsThatNeedIndexesAdjusted = allFields.filter(
      one => one.position > newAMLFieldPosition
    );
    otherFieldsThatNeedIndexesAdjusted.forEach(one => {
      one.position = one.position + amlSubfieldCount + 1;
    });
  }

  // append field to user fields
  allFields.push(newFieldObject);

  if (
    /* don't include lookup and user_lookup here */
    field.uiType === 'thirdfort' ||
    field.uiType === 'worldcheck' ||
    field.uiType === 'worldcheck_org' ||
    field.uiType === 'currency_conversion' ||
    field.uiType === 'credas' ||
    field.uiType === 'credas_out_of_scope'
  ) {
    // change index
    newFieldObject.position = newAMLFieldPosition + 1;
    // thirdfort needs to add five extra subfields
    newFieldObject.lookupInfo = {
      subFields: subFieldData.map(one => {
        return { internalId: one.internalId };
      })
    };

    const subFieldIds = subFieldData.map(one => one.subFieldId);

    let subField, subFieldIndex;

    for (
      subFieldIndex = 0;
      subFieldIndex < subFieldIds.length;
      subFieldIndex++
    ) {
      let currentSubFieldData = subFieldData[subFieldIndex];
      subField = {
        internalId: currentSubFieldData.subFieldId,
        position: newAMLFieldPosition + 2 + subFieldIndex,
        title: currentSubFieldData.title,
        type: currentSubFieldData.type,
        uiType: currentSubFieldData.uiType,
        lookupInfo: {
          subFields: [],
          subFieldInfo: {
            parentFieldId: internalId,
            externalFormFieldId:
              newFieldObject.lookupInfo.subFields[subFieldIndex].internalId
          }
        }
      };
      if (currentSubFieldData.options) {
        subField.options = currentSubFieldData.options;
      }
      if (currentSubFieldData.defaultValue) {
        subField.defaultValue = currentSubFieldData.defaultValue;
      }
      if (currentSubFieldData.editable) {
        subField.lookupInfo.subFieldInfo.editable = true;
      }
      if (currentSubFieldData.required) {
        subField.required = true;
      }
      if (currentSubFieldData.description) {
        subField.description = currentSubFieldData.description;
      }
      allFields.push(subField);
    }
    allFields.sort((a, b) => {
      let aPos, bPos;
      aPos = a.position;
      bPos = b.position;
      if (aPos < bPos) {
        return -1;
      }
      if (aPos > bPos) {
        return 1;
      }
      return 0;
    });
  } else if (isNumber(adjustedAfter)) {
    allFields = reorder(allFields, -1, adjustedAfter + 1);
  }

  const fields = [];
  const adminFields = [];

  allFields.forEach((f, i) => {
    f.position = i;

    if (f.isAdminOnly) {
      adminFields.push(f);
    } else {
      fields.push(f);
    }

    delete f.isAdminOnly;
  });

  // FormBuilderFieldForm checks this variable when deciding to animate
  window.formBuilderNewestField = internalId;
  window.formBuilderNewestFieldUIType = field.uiType;

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

const deleteField = (state, payload) => {
  const { id, fieldId } = payload;
  let form = dotProp.get(state, id);
  let { fields, adminFields } = form;

  // filter out the field to delete
  fields = fields.filter(f => f.internalId !== fieldId);
  adminFields = adminFields.filter(f => f.internalId !== fieldId);

  // normalize the position
  form = normalizeOrder({
    ...form,
    fields,
    adminFields
  });

  // return the new state
  return dotProp.set(state, id, form);
};

const deleteFields = (state, payload) => {
  const { id, fieldIds } = payload;
  let form = dotProp.get(state, id);
  let { fields, adminFields } = form;

  // filter out the field to delete
  fields = fields.filter(f => fieldIds.indexOf(f.internalId) === -1);
  adminFields = adminFields.filter(f => fieldIds.indexOf(f.internalId) === -1);

  // normalize the position
  form = normalizeOrder({
    ...form,
    fields,
    adminFields
  });

  // return the new state
  return dotProp.set(state, id, form);
};

const findFieldAndMutate = (list, fieldId, field) => {
  const index = list.findIndex(f => f.internalId === fieldId);

  if (index >= 0) {
    list[index] = {
      ...list[index],
      ...field
    };
  }
};

const updateField = (state, payload) => {
  const { id, fieldId, field } = payload;
  const form = dotProp.get(state, id);
  let { fields, adminFields } = form;

  findFieldAndMutate(fields, fieldId, field);
  findFieldAndMutate(adminFields, fieldId, field);

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields,
    adminFields
  });
};

/**
 * Finds the first field with a given ID in the form object.
 *
 * Returns an object containing the `field` object and its dot-prop `path` in the form object, with a value like `userFields.0` or `adminFields.6`.
 *
 * If the field does not exist, it returns `null`.
 *
 * Example usage:
 *
 * ```js
 * let { field, path } = getField(form, fieldId);
 * ```
 */
const getField = (form, fieldId) => {
  let { fields, adminFields } = form;
  let i;

  // search user fields
  i = fields.findIndex(f => f.internalId === fieldId);

  if (i >= 0) {
    return {
      field: fields[i],
      path: `fields.${i}`
    };
  }

  // search admin fields
  i = adminFields.findIndex(f => f.internalId === fieldId);

  if (i >= 0) {
    return {
      field: adminFields[i],
      path: `adminFields.${i}`
    };
  }

  // no results
  return null;
};

const duplicateField = (state, payload) => {
  const { id, fieldId } = payload;
  const form = dotProp.get(state, id) || {};
  let { fields, adminFields } = form;
  let { field, path } = getField(form, fieldId);

  if (field.uiType === 'thirdfort') {
    return addField(state, {
      after: field.position,
      field: { type: 'text', uiType: 'thirdfort', title: field.title },
      id: id
    });
  }

  const incrementAllLaterFields = f => ({
    ...f,
    position: f.position > field.position ? f.position + 1 : f.position
  });

  // increment position of all fields after this one
  form.fields = fields.map(incrementAllLaterFields);
  form.adminFields = adminFields.map(incrementAllLaterFields);

  // path looks like "fields.1"
  const [array, index] = path.split('.');

  // generate new ID
  const internalId = payload.internalId || generateFieldId();

  // increment position
  const position = field.position + 1;

  // insert new field
  form[array].splice(index + 1, 0, {
    // immutable copy of source field
    ...dotProp.get(form, path),
    internalId,
    position
  });

  // FormBuilderFieldForm checks this variable when deciding to animate
  window.formBuilderNewestField = internalId;
  window.formBuilderNewestFieldUIType = field.uiType;

  // return the new state
  return dotProp.set(state, id, {
    ...form,
    fields: form.fields || [],
    adminFields: form.adminFields || []
  });
};

/**
 * Normalizes the `position` property of the fields in both `fields` and `adminFields` arrays, so their values run from 0 to 1 less than the sum of the array lengths.
 */
export const normalizeOrder = _form => {
  const form = cloneDeep(_form);

  // a number bigger than any form's field count
  let upperBound = 1e9;

  // a "stack" for each array, by descending `position` value, so we can pop off the lowest-valued field, fix its `position` prop, and push to the result
  const stacks = {
    fields: form.fields || [],
    adminFields: form.adminFields || []
  };

  ['fields', 'adminFields'].forEach(key => {
    // verify that upperBound is bigger than any found position in either stack
    stacks[key].forEach(f => {
      if (f.position > upperBound) {
        upperBound = f.position + 1;
      }
    });
  });

  ['fields', 'adminFields'].forEach(key => {
    // add position props for any that are missing, use upperBound to order them last
    stacks[key].forEach(f => {
      if (!('position' in f)) {
        upperBound++;
        f.position = upperBound;
      }
    });

    // reverse sort so we pop off the smallest
    stacks[key].sort((a, b) => b.position - a.position);
  });

  // arrays for our results
  const results = {
    fields: [],
    adminFields: []
  };

  // loop parameters
  const max = stacks.fields.length + stacks.adminFields.length - 1;
  let i = 0;

  // POP: (P)ops a field from a stack, sets its (O)rder, then (P)ushes to the result
  const POP = (key, j) => {
    const field = stacks[key].pop();
    if (field) {
      field.position = j;
      results[key].push(field);
    }
  };

  // Gets the `position` value of the last field in the array, which is next in the stack. Since we are comparing to get the lower value of the two stacks, if a stack is empty, return `Infinity`
  const getOrderOfLast = key => {
    // get last object
    const [last] = stacks[key].slice(-1);

    // nothing found, return the highest number
    if (!last) return Infinity;

    // return the position value
    return last.position;
  };

  // the loop
  while (i <= max) {
    // POP from whichever stack has the lower position value up next
    if (getOrderOfLast('fields') <= getOrderOfLast('adminFields')) {
      POP('fields', i);
    } else {
      POP('adminFields', i);
    }
    i++;
  }

  // return the whole form
  return {
    ...form,
    ...results
  };
};

const updateFieldAccess = (state, payload) => {
  const { id, fieldId, access } = payload;
  let form = dotProp.get(state, id);
  let { path } = getField(form, fieldId);
  let allFields = getAllFields(form);
  let mySubFields, subField;

  // name of field's new home
  const array = access === 'admin' ? 'adminFields' : 'fields';

  // get immutable copy of the field
  let field = dotProp.get(form, path);

  // delete the field from its current path
  form = dotProp.delete(form, path);

  // push it to its new home and sort
  form[array].push(field);

  // if this is a lookup handle subfields as well
  if (FIELD_TYPES_WITH_SUBFIELDS.includes(field.uiType)) {
    mySubFields = allFields.filter(
      one =>
        one.lookupInfo &&
        one.lookupInfo.subFieldInfo &&
        one.lookupInfo.subFieldInfo.parentFieldId === fieldId
    );
    mySubFields.forEach(one => {
      subField = dotProp.get(form, getField(form, one.internalId).path);
      form = dotProp.delete(form, path);
      form[array].push(subField);
    });
  }
  form[array].sort((a, b) => a.position - b.position);

  // return the new state
  return dotProp.set(state, id, form);
};

const multiUpdateFieldAccess = (state, payload) => {
  const { id, fieldIsToSet, value } = payload;
  const access = value ? 'admin' : 'user';
  const array = access === 'admin' ? 'adminFields' : 'fields';
  let form = dotProp.get(state, id);
  fieldIsToSet.forEach(fieldId => {
    let { path } = getField(form, fieldId);
    let allFields = getAllFields(form);
    let mySubFields, subField;

    // name of field's new home

    // get immutable copy of the field
    let field = dotProp.get(form, path);

    // delete the field from its current path
    form = dotProp.delete(form, path);

    // push it to its new home and sort
    form[array].push(field);

    // if this is a lookup handle subfields as well
    if (FIELD_TYPES_WITH_SUBFIELDS.includes(field.uiType)) {
      mySubFields = allFields.filter(
        one =>
          one.lookupInfo &&
          one.lookupInfo.subFieldInfo &&
          one.lookupInfo.subFieldInfo.parentFieldId === fieldId
      );
      mySubFields.forEach(one => {
        subField = dotProp.get(form, getField(form, one.internalId).path);
        form = dotProp.delete(form, path);
        form[array].push(subField);
      });
    }
  });
  form[array].sort((a, b) => a.position - b.position);

  // return the new state
  return dotProp.set(state, id, form);
};

const multiset = (state, payload) => {
  const { id, fieldIsToSet, attribute, value } = payload;
  let form = dotProp.get(state, id);
  let { fields, adminFields } = form;

  const fieldsCopy = SerializationCopy(fields);
  const adminFieldsCopy = SerializationCopy(adminFields);

  const FIELD_TYPES_THAT_CANT_BE_REQUIRED = [
    'instructional_text',
    'section_break',
    'score'
  ];

  fieldsCopy.forEach(oneField => {
    if (
      attribute === 'required' &&
      FIELD_TYPES_THAT_CANT_BE_REQUIRED.includes(oneField.uiType)
    ) {
      return;
    }
    if (
      oneField &&
      oneField.internalId &&
      fieldIsToSet &&
      fieldIsToSet.includes(oneField.internalId)
    ) {
      oneField[attribute] = value;
    }
  });

  adminFieldsCopy.forEach(oneField => {
    if (
      attribute === 'required' &&
      FIELD_TYPES_THAT_CANT_BE_REQUIRED.includes(oneField.uiType)
    ) {
      return;
    }
    if (
      oneField &&
      oneField.internalId &&
      fieldIsToSet &&
      fieldIsToSet.includes(oneField.internalId)
    ) {
      oneField[attribute] = value;
    }
  });

  // return the new state
  const newState = dotProp.set(state, id, {
    ...form,
    fields: fieldsCopy,
    adminFields: adminFieldsCopy
  });
  return newState;
};

const generateFieldId = () => 'f' + generateId();

const loadForm = (state, id, payload) => {
  return dotProp.set(state, id, normalizeOrder(payload));
};

// reducer as default export
const formBuilder = (state = {}, { type, payload }) => {
  if (!payload) {
    return state;
  }

  const { id } = payload;

  if (!id) {
    return state;
  }

  switch (type) {
    case LOAD_FORM:
      return loadForm(state, id, payload);

    case UPDATE_FORM:
      return dotProp.set(state, id, mergePayload(payload));

    case REORDER_FIELDS:
      return reorderFields(state, payload);

    case REORDER_SECTIONS:
      return reorderSections(state, payload);

    case UPDATE_CURRENCY_CONVERSION_FIELD:
      return updateCurrencyConversionField(state, payload);

    case UPDATE_LOOKUP_FIELD:
      return updateLookupField(state, payload);

    case UPDATE_SCORE_FIELD:
      return updateScoreField(state, payload);

    case ADD_FIELD:
      return addField(state, payload);

    case DELETE_FIELD:
      return deleteField(state, payload);

    case DELETE_FIELDS:
      return deleteFields(state, payload);

    case UPDATE_FIELD:
      return updateField(state, payload);

    case DUPLICATE_FIELD:
      return duplicateField(state, payload);

    case UPDATE_FIELD_ACCESS:
      return updateFieldAccess(state, payload);

    case MULTI_UPDATE_FIELD_ACCESS:
      return multiUpdateFieldAccess(state, payload);

    case MULTISET:
      return multiset(state, payload);

    default:
      return state;
  }
};

export default formBuilder;
